<template>
  <b-container id="content-verification" fluid class="bg-login full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side"> </b-col>
      <b-col
        cols="12"
        md="6"
        class="right-side px-3 d-flex justify-content-center align-item-center"
      >
        <div class="inner-wrapper" data-anima="right" v-if="!loading">
          <span class="logo mb-5">
            <img src="@/assets/img/logo.svg" />
          </span>

          <span>
            <div data-anima="top" v-if="!confirmed">
                <h4 class="title">{{ $t("views.seller.verification.text_0006")}}</h4>
                <p class="sub-title" v-html="$t('views.seller.verification.text1')"></p>
            </div>

            <div data-anima="top" v-if="confirmed">
                <h4 class="title">{{ $t("views.seller.verification.text_0003")}} <img src="~@/assets/img/icons/checked.png" /></h4>
                <p class="sub-title" v-html="$t('views.seller.verification.text_0004')"></p>
            </div>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="btn-acoes"
              >
                <!-- <button class="btn-login button button-primary" @click="onSubmit()">Enviar novo link de verificação</button> -->
                <BaseButton variant="secondary" @click="onSubmit()" class="button-secondary_outline" v-if="!confirmed"
                  >{{ $t('views.seller.verification.send_new_verification_link') }}</BaseButton
                >
                <BaseButton variant="secondary" @click="acessarConta()" v-if="confirmed" class="access-account"
                  >{{ $t('views.seller.verification.text_0002') }}</BaseButton
                >
              </b-overlay>
            </b-row>

            <b-row class="mt-4 linha-possui-conta" v-if="!confirmed">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="btn-criar-conta"
              >
               {{ $t("views.seller.login.text_1573") }} <router-link
                  :to="{ name: 'Login' }"
                  class="recupera-senha-text"
                  > &nbsp;{{ $t("views.seller.login.text_1574") }} <img src="~@/assets/img/icons/long-arrow-orange.svg" /></router-link
                >
              </b-overlay>
            </b-row>
          </span>
        </div>

        <div
          v-if="loading"
          class="py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
          <p class="recupera-senha-text ml-4">{{ $t('views.seller.verification.processing') }}</p>
        </div>
        <div class="mt-5 termos-privacidade">
              <a
                class="links-help mr-1"
                 href="https://www.voompcreators.com.br/termos-e-documentacoes/"
                target="_blank"
                >{{ $t("views.seller.login.text_1568") }}</a
              >
              <span class="links-help mr-1">|</span>
              <a
                class="links-help"
                href="https://www.canaldatransparencia.com.br/cogna/"
                target="_blank"
                >{{ $t("views.seller.login.text_1566") }}</a
              >
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import EmailVerificationService from "@/services/resources/EmailVerificationService";
const serviceEmail = EmailVerificationService.build();

import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

import Cookies from "js-cookie";
// import crisp from '@/mixins/crisp.js'

export default {
  name: "Verificação",
  data() {
    return {
      email: "",
      success: true,
      confirmed: false,
      loading: false,
      hash: null,
      interval: null,
    };
  },
  // mixins: [crisp],
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch((err) => {});
    },
    acessarConta() {
      this.$router.push(`/dashboard${window.location.search}`);
    },
    onSubmit() {
      this.loading = true;
      let data = {
        email: this.$store.getters.getProfile.email,
      };
      serviceEmail
        .create(data)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast(this.$t('views.seller.verification.new_verification_link_sent'), {
              title: this.$t('views.seller.verification.verification'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verificaDados() {
      this.$store
        .dispatch("emailVerification")
        .then((resp) => {
          if (resp) {
            this.$router.push(`/dashboard${window.location.search}`);
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getHash() {
      this.loading = true;
      let data = {
        id: this.$route.params.hash,
      };
      serviceEmail
        .update(data)
        .then((resp) => {
          if (this.$store.getters.isAuthenticated) {
            if (resp.success) {
              this.confirmed = true;
              this.loading = false;
              this.$store.state.user = resp.user;
              Cookies.set("user", JSON.stringify(resp.user), {
                expires: 365,
              });
              clearInterval(this.interval);
              window.location.reload();
            } else {
              if (this.$store.getters.verifyEmail) {
                this.$router
                  .push(`/dashboard${window.location.search}`)
                  .catch();
                return;
              } else {
                this.verificaDados();
              }
            }
          } else {
            this.loading = false;
            this.confirmed = true;
            if (resp.success) {
              clearInterval(this.interval);
              let access_token = "Bearer " + resp.access_token;
              Cookies.set("access_token", access_token, {
                expires: 365,
              });
              this.$store.state.auth.access_token = access_token;
              this.$store.state.user = resp.user;
              Cookies.set("user", JSON.stringify(resp.user), {
                expires: 365,
              });
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.verificaDados();
        });
    },
    initVerify() {
      if (this.$route.params.hash) {
        this.getHash();
      } else if (this.$store.getters.verifyEmail) {
        this.$router.push(`/dashboard${window.location.search}`).catch();
        return;
      } else {
        this.verificaDados();
      }
    },
    pollData() {
      this.interval = setInterval(() => {
        this.initVerify();
      }, 10000);
    },

    addMeta() {
      let data = {
        meta_key: "onboarding",
        meta_value: 0,
      };
      serviceMeta.create(data);
    },

    fetchMetas() {
      if (this.$store.state.isAuthenticated) {
        let data = {
          id: "onboarding",
        };
        serviceMeta.read(data).then((response) => {
          if (typeof response.meta_value == "undefined") {
            this.addMeta();
          }
        });
      }
    },
    installTags() {
    }
  },
  mounted() {
    this.loading = true;
    this.installTags();
    this.fetchMetas();
    this.initVerify();
    this.pollData();

    // add crisp no greenn

    // let notify = document.getElementById("1WZET722PJibpmL_crisp");
    // if (notify === null) {
    //   window.$crisp = [];

    //   this.crispWitUser();
    //   this.appendScriptCrisp();
    // } else {
    //   const crisp = document.querySelector(".crisp-client");
    //   crisp.style.display = "block";
    //   this.crispWitUser(false);
    // }
    // fim crisp
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.text-green {
  color: $base-color;
  font-weight: bold;
}

.bg-login {
  background: $base-color;
}

.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 25px;
}

.left-side {
  background-image: url("~@/assets/img/cadastro-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px;
  height: 100%;

  .inner-wrapper {
    margin-top: 40px;
    width: 70%;

    .logo {
      img {
        width: 143.83px;
        margin-bottom: 40px;
      }
    }
  }
}

input[type="text"], input[type="password"], input[type="email"]{
  height: 53px!important;
  padding: 16px 24px 16px 24px!important;
  border-radius: 4px!important;
  border: 1px solid #DEDEDE!important;
  
  font-family: 'Montserrat', sans-serif!important;
  font-size: 14px!important;
  font-weight: 400!important;
  line-height: 21px!important;
  letter-spacing: 0em!important;
  text-align: left!important;

}
.button-secondary_outline{
  width: auto;
  color: #002e74;
  background: #ffffff!important;
  border: 1px solid #002e74;
  cursor: pointer;
  width: 100%;
}

.access-account{
  width: auto;
  width: 100%;
}

.recupera-senha-text {
  color: #81858e;
  font-size: 14px;
}
.links-help {
  font-size: 14px;
  color: #81858e;
  margin-top: 20px;
}
.btn-acoes {
  width: 100%;
}
.btn-criar-conta{
  margin: 0 auto;
}
.logo img {
    width: 138.83px !important;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
@media screen and (max-width: 768px) {
  .inner-wrapper {
    width: 85% !important;
  }
  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }
  .links-help {
    margin: 0;
  }
}


label{
  font-weight: 600!important;
  margin-bottom: 4px !important;
  font-size: 16px!important;
  line-height: 24px!important;
}


.title{
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  color: #2E2E2E ;
}


.sub-title{
    margin-top: 15px;
    margin-bottom: 45px;
    font-size: 14px;
}
.title img{
  margin-left: 10px;
  width: 20px;
  height: 20px;
  bottom: -10px;
}
.linha-possui-conta{
      display: inline-flex;
      font-size: 10pt;
      color: #7c8089;
      width: 100%;
}

.linha-possui-conta a{
      display: inline-flex;
      font-size: 10pt;
      color: #f58322;
}

.linha-possui-conta a img{
    transform: rotate(180deg);
    height: 8px;
    top: 6px;
    margin-left: 8px;
    position: relative;
}

.termos-privacidade a{
    font-size: 14px;
    color: #333;
}
.termos-privacidade{
    bottom: 15px;
    position: absolute;
    text-align: center;
}
.text,
.logout {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}

.logout {
  display: block;
  color: #333;
  margin-top: 20px;
}
.main {
  padding: 0px !important;
}
</style>